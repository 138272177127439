$break-point-b: 768px;
#arrow-up{
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 5vw;
  width: 80px;
  height: 80px;
  z-index: 120;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  .button-back-sc{
    font-family: 'icony_moryc';
    color: white;
    font-size: 80px;
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  }

  @media only screen and (min-width: $break-point-b) {
    bottom: 25px;
    right: 100px;
    width: 80px;
    height: 80px;
    z-index: 100;

    .button-back-sc{
      color: white;
      font-size: 80px;
    }

  }

}
